import logo from '../images/pigott_lodge.jpg';

function PublicHome() {

    return (
        <div className="App">
            <header className="App-header">
                <p>
                Cascade Scout Reservation Staff Alumni Associaion
                <br />
                More Content Coming Soon!
                </p>
                <img src={logo} className="App-logo" alt="logo" />

            </header>
        </div>
    );
}
export default PublicHome;